import queryString from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

import { SearchFilterContentTypes } from '../../Shared/enums';
import {
  VideoQualityType,
  VideoSelectedSearchFilterOptions,
} from '../containers/MenuContainerInterfaces';
import { UsageRights } from '../entities/UsageRights';
import {
  categoryTypes,
  templateTypes,
} from '../entities/VideoSearchFilterOptions';
import VideoSearchOptions from '../entities/VideoSearchOptions';
import { getDecodedSearchTerm } from '../utils/searchUtils';
import buildContentAgnosticSearchOptions from './buildContentAgnosticSearchOptions';

interface VideoSearchPageQueryParams {
  // A urlId of a single category.
  categories?: string;
  // Frame rates example: frame_rates[0]=fps24&frame_rates[1]=fps25)
  frame_rates?: string[];
  has_property_released?: boolean;
  has_talent_released?: boolean;
  max_duration?: number;
  min_duration?: number;
  usage_rights?: UsageRights;
  video_quality?: VideoQualityType;
}

export default function useVideoSearchOptionsFromLocation(): Partial<VideoSelectedSearchFilterOptions> {
  const { pathname, search, key } = useLocation();

  const selectedSearchFilterOptions = useMemo<
    Partial<VideoSelectedSearchFilterOptions>
  >(() => {
    const queryParams: VideoSearchPageQueryParams = queryString.parse(search, {
      parseBooleans: true,
      parseNumbers: true,
      arrayFormat: 'index',
    });
    const pathSegments = pathname.split('/').filter(Boolean);

    const updates: Partial<VideoSelectedSearchFilterOptions> =
      buildContentAgnosticSearchOptions(queryParams);

    // contentType
    switch (pathSegments[0]) {
      case 'video':
        updates.contentType = SearchFilterContentTypes.Footage;
        break;
      case 'motion-graphics':
        updates.contentType = SearchFilterContentTypes.Motion_bgs;
        break;
      case 'templates':
        updates.contentType = SearchFilterContentTypes.Templates;
        break;
    }

    // query param names from VideoSearchQueryParams.php and SearchQueryParams.php
    // also see VideoRouteTranslator::getAllowedParams()

    // resolution
    if (queryParams.video_quality) {
      updates.videoQuality = queryParams.video_quality;
    }

    // duration
    if (queryParams.min_duration) {
      updates.minDuration = queryParams.min_duration;
    }
    if (queryParams.max_duration) {
      updates.maxDuration = queryParams.max_duration;
    }

    // property release
    if (queryParams.has_property_released) {
      updates.propertyReleased = queryParams.has_property_released;
    }

    // talent release
    if (queryParams.has_talent_released) {
      updates.talentReleased = queryParams.has_talent_released;
    }

    // usage rights
    if (queryParams.usage_rights) {
      updates.usageRights = queryParams.usage_rights;
    }

    // frame rates
    if (queryParams.frame_rates?.length > 0) {
      updates.frameRates = queryParams.frame_rates;
    }

    // template type (comes from path segment if present)
    if (updates.contentType === SearchFilterContentTypes.Templates) {
      const possibleTemplateType = templateTypes.find(
        ({ urlId }) => urlId === pathSegments[1]
      );
      if (possibleTemplateType) {
        updates.templateType = possibleTemplateType.value;
      }
    }

    // categories
    // query param should only be present if template type is also present
    const categoryUrlId =
      queryParams.categories ||
      (pathSegments[1] === 'search' ? undefined : pathSegments[1]);
    if (categoryUrlId) {
      const category = categoryTypes.find(
        ({ contentType, urlId }) =>
          contentType === updates.contentType && urlId === categoryUrlId
      );
      if (category) {
        updates.categories = category.value;
      }
    }
    // We need categories to be [template id],[category id] for legacy reasons.
    if (updates.templateType) {
      updates.categories = [updates.templateType, updates.categories]
        .filter(Boolean)
        .join(',');
    }

    // search term
    const searchSegmentIndex = pathSegments.findIndex((s) => s === 'search');
    if (searchSegmentIndex > -1) {
      const searchTerm = pathSegments[searchSegmentIndex + 1] || '';
      updates.searchTerm = getDecodedSearchTerm(searchTerm);
    }

    return new VideoSearchOptions().update(updates);
  }, [pathname, search, key]);

  return selectedSearchFilterOptions;
}
